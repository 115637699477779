import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { sortableToSurvey, surveyToSortable } from '../../../../utils';
import { SURVEY_TYPES } from '../../../../utils/dataMappers';
import FindQuestionModal from '../../../components/findQuestionModal';
import ErrorMessage from '../../../components/formV3/errors/ErrorMessage';
import Sortable from '../../../components/sortable';
import { INITIAL_STEP, INITIAL_SURVEY } from '../FormModel/formInitialValues';
import { yupSchemaMedicTemplate, yupSchemaStore } from '../FormModel/validationSchema';
import Menu from '../Menu';
import ConsentHeader from './ConsentHeader';
import Step from './ConsentSteps/Step';

const CreateForm = ({
  initialValues,
  disabled,
  templateUpsertRequest,
  medicTemplateUpsert,
  surveyCurrentDelete,
  onError,
  can,
  isStoreTemplate,
  localId,
  clearSelectedMedicTemplate,
  clearSelectedTemplate,
}) => {
  const navigate = useNavigate();
  const [isReOrderModalOpen, setIsReOrderModalOpen] = useState(false);
  const [isFindQModalOpen, setIsFindQModalOpen] = useState(false);
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return { ...initialValues } || { ...INITIAL_SURVEY };
    }, [initialValues]),
    resolver: yupResolver(isStoreTemplate ? yupSchemaStore : yupSchemaMedicTemplate),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const { handleSubmit, watch, setValue, control, reset, trigger } = formMethods;
  const {
    fields: stepFields,
    remove,
    append,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });
  const stepsWatch = useWatch({
    control,
    name: 'steps',
  });

  useEffect(() => {
    return function clean() {
      if (isStoreTemplate) {
        clearSelectedTemplate && clearSelectedTemplate();
      } else {
        clearSelectedMedicTemplate && clearSelectedMedicTemplate();
      }
    };
  }, [clearSelectedMedicTemplate, clearSelectedTemplate, isStoreTemplate]);

  useEffect(() => {
    const newInitialValues = initialValues || INITIAL_SURVEY;
    reset({ ...newInitialValues });
  }, [initialValues, reset]);

  const onErrors = (errors) => {
    // eslint-disable-next-line no-console
    console.error('errors', errors);
    if (onError) onError(errors);
  };

  const onSubmits = (data) => {
    trigger(null, { shouldFocus: true });
    let stepIndex = 0;

    let newTemplate = {
      id: data.id || null,
      type_id: data.type_id,
      speciality_id: data.speciality_id,
      is_published: data.is_published,
      side: _.get(data, 'isSide') === true ? 'on' : 'off',
      radius: _.get(data, 'isRadius') === true ? 'on' : 'off',
      translation: {
        id: data.translation?.id,
        language_id: localId,
        title: data.translation?.title || null,
        description:
          Array.isArray(data.translation?.description) && data.translation?.description.length
            ? JSON.stringify(data.translation?.description.map((t) => t.value))
            : null,
        comment: data.translation?.comment || null,
        survey_model_files_id: data.translation?.survey_model_files_id || null,
      },
      steps: data.steps.map((s) => {
        stepIndex = stepIndex + 1;
        return formatStepOnSubmit(s, stepIndex, localId, data.type);
      }),
    };

    if (isStoreTemplate) {
      templateUpsertRequest({
        ...newTemplate,
        translation: { ...newTemplate.translation, consent_pdf_template: data.translation?.consent_pdf_template },
      });
    } else {
      medicTemplateUpsert({
        ...newTemplate,
        medic_id: data.medic_id,
        medical_teams_id: data.medical_teams_id,
      });
    }
    if (surveyCurrentDelete) {
      surveyCurrentDelete();
    }
  };

  const onBackClick = () => {
    if (surveyCurrentDelete) {
      surveyCurrentDelete();
    }
    navigate('/forms');
  };

  const onAddStep = () => {
    const newStep = INITIAL_STEP(stepFields.length + 1);
    append(newStep);
  };

  const onReorderComplete = (newData) => {
    const newSteps = sortableToSurvey(newData, stepsWatch);
    newSteps.forEach((step, index) => {
      setValue(`steps.${index}.questions`, step.questions);
      setValue(`steps.${index}`, step);
    });
    setIsReOrderModalOpen(false);
  };

  const handleAddQuestions = (newQuestions) => {
    if (!stepsWatch.length) {
      onAddStep();
    }
    const index = stepsWatch.length ? stepsWatch.length - 1 : 0;
    const newLastStepQuestions = _.get(stepsWatch, [index, 'questions'], []);
    let start = newLastStepQuestions.length || 0;
    newQuestions = newQuestions.map((q) => {
      start = start + 1;
      return {
        ..._.omit(q, ['id', 'key', 'step_id']),
        isNew: true,
        step_id: _.get(stepsWatch, [index, 'id'], index + 1),
        number: start,
      };
    });
    setValue(`steps.${index}.questions`, [...newLastStepQuestions, ...newQuestions]);
    setIsFindQModalOpen(false);
  };

  const handleFindQuestionModalClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsFindQModalOpen(false);
  };

  const handleSortableClose = () => {
    setIsReOrderModalOpen(!isReOrderModalOpen);
  };

  let counter = 0;

  const handleOpenSearchModal = () => {
    setIsFindQModalOpen(true);
  };
  const handleOpenReorderModal = () => {
    setIsReOrderModalOpen(true);
  };
  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)}>
          <div className="d-flex flex-row">
            <Menu
              onBackClick={onBackClick}
              onAddStep={onAddStep}
              onSearchQuestion={handleOpenSearchModal}
              onReorder={handleOpenReorderModal}
              can={can}
            />
            <div className="d-flex flex-column w-100 createform-header-container">
              <div className={'createform-header-container'}>
                <ConsentHeader {...{ control }} createMode defaultValues={initialValues} can={can} isStoreTemplate={isStoreTemplate} />
              </div>
              <div className={'createform-body-container'}>
                {stepFields.map((step, index) => {
                  return (
                    <div key={step.key}>
                      <Step
                        step={step}
                        onDeleteStep={() => remove(index)}
                        droppable
                        disabled={disabled}
                        prefixName={`steps.${index}`}
                        index={index}
                        can={can}
                        localId={localId}
                        {...{ control }}
                      />
                    </div>
                  );
                })}
              </div>
              <Button type="primary" htmlType="submit" style={{ width: '55%' }}>
                Enregistrer
              </Button>
            </div>
          </div>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <>
            <div className="container">
              <div style={{ flexDirection: 'column', marginRight: 0, marginLeft: 'auto' }}>
                <div style={{ margin: 'auto' }}>
                  Render <span>{counter++}</span>
                </div>
                <div style={{ margin: 'auto' }}>
                  <ErrorMessage />
                </div>
                <div style={{ margin: 'auto' }}>
                  {!!process.env.REACT_APP_DEBUG_FORM && <pre>{JSON.stringify(watch(), getCircularReplacer(), 2)}</pre>}
                </div>
              </div>
            </div>
          </>
        )}
      </FormProvider>
      {isReOrderModalOpen && <Sortable list={surveyToSortable(stepsWatch)} onExit={handleSortableClose} onFinish={onReorderComplete} />}
      {isFindQModalOpen && (
        <FindQuestionModal onClose={handleFindQuestionModalClose} onSelected={handleAddQuestions} isOpen={isFindQModalOpen} localId={localId} />
      )}
    </div>
  );
};

CreateForm.propTypes = {
  can: PropTypes.shape({
    publish: PropTypes.bool,
    editTitle: PropTypes.bool,
    editDescription: PropTypes.bool,
    editSpecialty: PropTypes.bool,
    side: PropTypes.shape({
      canEdit: PropTypes.bool,
      canAnswer: PropTypes.bool,
    }),
    radius: PropTypes.shape({
      canEdit: PropTypes.bool,
      canAnswer: PropTypes.bool,
    }),
    steps: PropTypes.shape({
      add: PropTypes.bool,
      delete: PropTypes.bool,
      canAnswer: PropTypes.bool,
      editTitle: PropTypes.bool,
      reOrder: PropTypes.bool,
      question: PropTypes.shape({
        add: PropTypes.bool,
        delete: PropTypes.bool,
        editQuestion: PropTypes.bool,
        editDesc: PropTypes.bool,
        rewording: PropTypes.shape({
          canEdit: PropTypes.bool,
          canRead: PropTypes.bool,
        }),
        data: PropTypes.shape({
          canEdit: PropTypes.bool,
          canAnswer: PropTypes.bool,
        }),
        completeData: PropTypes.bool,
      }),
    }),
  }),
};

export default CreateForm;

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const formatQuestionOnSubmit = (q, s, index, localId, type) => {
  const list = _.get(q, 'translation.data.list');
  return {
    id: q.isNew ? -1 : q.id,
    step_id: s.isNew === true ? -1 : s.id,
    type_id: q.type_id,
    number: index,
    translation: {
      id: q.translation?.id,
      language_id: localId,
      question_id: q.translation?.question_id,
      question: q.translation?.question,
      data: {
        ...q.translation?.data,
        list: [SURVEY_TYPES.checkbox_full, SURVEY_TYPES.multiple_choice].includes(type) && Array.isArray(list) ? list.map((l) => l.value) : list,
      },
      desc: q.translation?.desc,
      rewording: q.translation?.rewording,
      texte_pdf: q.translation?.texte_pdf,
      incompris: q.translation?.incompris,
    },
  };
};

const formatStepOnSubmit = (s, index, localId, type) => {
  let questionIndex = 0;
  return {
    id: s.isNew === true ? -1 : s.id,
    number: index,
    translation: {
      id: s.translation?.id,
      language_id: localId,
      // step_id: s.translation?.step_id,
      title: s.translation?.title,
    },
    questions: s.questions.map((q) => {
      questionIndex = questionIndex + 1;
      return formatQuestionOnSubmit(q, s, questionIndex, localId, type);
    }),
  };
};
